import LoadingPlx from "@/components/loadingPlx.tsx";
import { useLocale } from "@/polyglotplug";
import { get } from "@/server/api";
import { ComponentType, FC, ReactNode, Suspense, lazy, useCallback, useEffect, useState } from "react";
import { BrowserRouter, Navigate, RouteObject, useRoutes } from "react-router-dom";
import { TOKEN_KEY } from "../../store/account/service";
import PCMadelPlx from "./components/PCMadelPlx";
import './index.less';
import { filterRoutersConfig } from "./utils/filterRoutersConfig";
export type IObjectRouter = RouteObject & {
    layout?: boolean
}
const Home = lazy(() => import('./views/Home'));
const Render = lazy(() => import('./views/Render'));
const Progress = lazy(() => import('./views/Progress'));
// const Render2 = lazy(() => import('./views/Render2'));
// const Subscribe = lazy(() => import('./views/Subscribe'));
const Setting = lazy(() => import('./views/Setting'));
const Login = lazy(() => import('./views/Login'));
const Register = lazy(() => import('./views/Register'));
const RegisteredSuccessfully = lazy(() => import('./views/RegisteredSuccessfully'));
const EmailBinding = lazy(() => import('./views/EmailBinding'));
const GoogleAuth = lazy(() => import('./views/GoogleAuth'));
// const UpgradePlus = lazy(() => import('./views/UpgradePlus'));
// const AddCard = lazy(() => import('./views/AddCard'));
// const PaymentInformation = lazy(() => import('./views/PaymentInformation'));
const ResetPassword = lazy(() => import('./views/ResetPassword'));
const CreateNewPassword = lazy(() => import('./views/CreateNewPassword'));
const History = lazy(() => import('./views/History3'));
const TermsAndConditions = lazy(() => import('./views/TermsAndConditions'));

const Render2Type = lazy(() => import('./views/Render2Type'));
// const AddCardSuccessful = lazy(() => import('./views/AddCardSuccessful'));
const RenderDetail = lazy(() => import('./views/RenderDetail'));
const AssociationCode = lazy(() => import("./views/AssociationCode"));
const RenderCanvas = lazy(() => import("./views/RenderCanvas"));
const Landingpage = lazy(() => import("./views/Landingpage"));
const Smart = lazy(() => import("./views/Smart"));
const PCPage: FC = () => {
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(() => localStorage.getItem(TOKEN_KEY) ? true : false);
    const [open, setOpen] = useState<boolean>(false);
    const [noticeText, setNoticeText] = useState<string>("");
    const t = useLocale();

    // const language = useLanguage();
    // 定义路由守卫函数，用于检查用户是否已经登录
    const requireAuth = (Component: ComponentType<{}>): ReactNode => {
        if (typeof isLoggedIn === "boolean") {
            return isLoggedIn ? <Component /> : <Navigate to="/login" replace={true} />;
        } else {
            return <Navigate to="/login" replace={true} />;
        }
    };
    const routes: IObjectRouter[] = [
        { path: "/home", element: <Home /> },
        { path: "/render", element: <Render /> },
        { path: "/progress", element: <Progress />, layout: false },
        { path: "/login", element: <Login setIsLoggedIn={setIsLoggedIn} />, layout: false },
        { path: "/register", element: <Register /> },
        { path: "/registered-successfully", element: <RegisteredSuccessfully />, layout: false },
        { path: "/email-binding", element: <EmailBinding />, layout: false },
        { path: "/login-code", element: requireAuth(GoogleAuth), layout: false },
        // { path: "/subscribe", element: requireAuth(Subscribe) },
        // { path: "/upgrade-plus", element: requireAuth(UpgradePlus) },
        { path: "/profile", element: requireAuth(Setting) },
        // { path: "/add-card", element: requireAuth(AddCard), layout: false },
        // { path: "/payment-information", element: requireAuth(PaymentInformation), layout: false },
        { path: "/reset-password", element: <ResetPassword />, layout: false },
        { path: "/create-new-password", element: <CreateNewPassword /> },
        { path: "/history", element: <History /> },
        // { path: "/add-card-successful", element: requireAuth(AddCardSuccessful), layout: false },
        { path: "/terms_and_conditions", element: <TermsAndConditions /> },
        { path: "/render-2-type", element: <Render2Type />, layout: false },
        { path: "/render-detail", element: <RenderDetail /> },
        { path: "/association_code/:associationCode", element: <AssociationCode />, layout: false },
        { path: "/render-canvas", element: <RenderCanvas />,},
        { path: "/landingpage", element: <Landingpage />, layout: false },
        { path: "/test-smart", element: <Smart />, layout: false },

        { path: "*", element: <Navigate to="/home" replace /> },
    ];
    const IRoutes = () => useRoutes(filterRoutersConfig(routes));

    const getNotice = useCallback(
        async () => {
            let response: any = await get(`/info/app`);

            if (response.code === 200) {
                if (response.data.criticalNoticeTmplStatus === "1") {
                    setNoticeText(response.data.criticalNoticeTmplContent);
                    setOpen(true);
                }
            }
        },
        [],
    )

    useEffect(() => {
        getNotice();

    }, [getNotice])

    return (
        <div className="pc-page">
            <BrowserRouter>
                <Suspense fallback={<LoadingPlx />}>
                    <PCMadelPlx />
                    <IRoutes />
                </Suspense>
            </BrowserRouter>
            {open &&
                <div className="bulletin-box-fixed">
                    <div className="bulletin-box">
                        <div className="bulletin">
                            <img src={require("../../img/notice1.png")} alt="" />
                            <p>{noticeText}</p>
                            <span>{t("SYSTEM_MAINTENANCE_NOTICE")}</span>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}
export default PCPage;

