let RECAPTCHA_KEY: string,
  GOOGLE_CLIENT_KEY: string,
  APPLE_CLIENT_ID: string,
  FACEBOOK_APP_ID: string,
  CONTACT_EMAIL: string,
  GA_TRACKING_ID: string;
const VERSION_NUMBER = "1.2.18";
// beta  测试 
// release   生产
if (process.env.REACT_APP_ENV === "uat") {
  RECAPTCHA_KEY = "6Leuxt0nAAAAAOJkqvEihWhedZK8LXFCVqc5KFd2";
  GOOGLE_CLIENT_KEY =
    "293298905092-p9uf72l3uiiegfb9nkf6tj2a2g96reur.apps.googleusercontent.com";
  APPLE_CLIENT_ID = "com.gp.kainoai.apple";
  FACEBOOK_APP_ID = "631968539079509";
  CONTACT_EMAIL = "enquiry@kainoai.com";
  GA_TRACKING_ID = "G-086QD66BGX";
} else if (process.env.REACT_APP_ENV === "stg") {
  RECAPTCHA_KEY = "6LdPyaAmAAAAACg_xOcCrcH0lv1SDy-9og9NOxDy";
  GOOGLE_CLIENT_KEY =
    "39167683318-r714roiovdssu5dalvmrkf1ckke7b6mv.apps.googleusercontent.com";
  APPLE_CLIENT_ID = "com.gp.kainoai.apple";
  FACEBOOK_APP_ID = "631968539079509";
  CONTACT_EMAIL = "enquiry@kainoai.com";
  GA_TRACKING_ID = "G-086QD66BGX";
} else {
  RECAPTCHA_KEY = "6LdPyaAmAAAAACg_xOcCrcH0lv1SDy-9og9NOxDy";
  GOOGLE_CLIENT_KEY =
    "39167683318-r714roiovdssu5dalvmrkf1ckke7b6mv.apps.googleusercontent.com";
  APPLE_CLIENT_ID = "com.gp.kainoai.apple";
  FACEBOOK_APP_ID = "631968539079509";
  CONTACT_EMAIL = "enquiry@kainoai.com";
  GA_TRACKING_ID = "G-086QD66BGX";
}

export {
  APPLE_CLIENT_ID,
  CONTACT_EMAIL,
  FACEBOOK_APP_ID,
  GA_TRACKING_ID,
  GOOGLE_CLIENT_KEY,
  RECAPTCHA_KEY,
  VERSION_NUMBER
};

