import useUserDetail from '@/Hooks/useUserDetail';
import { setLocale } from '@/polyglotplug';
import { TOKEN_KEY } from '@/store/account/service';
import { staticLanguage$ } from '@/store/language';
import { BellOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Col, Dropdown, Row } from "antd";
import { FC, useCallback, useState } from "react";
import { useNavigate } from 'react-router-dom';
import logo from "../../../../img/header-logo.svg";
import { getLanguage } from '../../../../react-i18next/i18n';
import "./index.less";

type Language = "zh_CN" | "en_US" | "zh_TW"
const Navigation: FC = () => {
    const userInfo = useUserDetail();
    const navigate = useNavigate();
    const [locale, setLocales] = useState<string | undefined>(localStorage.getItem("language") || getLanguage());
    const goUser = useCallback(
        () => {
            const token = localStorage.getItem(TOKEN_KEY)
            if (token && userInfo && userInfo.id) {
                navigate("/user")
            } else {
                navigate("/login")
            }
        },
        [navigate, userInfo],
    )

    const goHome = useCallback(
        () => {
            navigate("/home")
        },
        [navigate],
    )
    const languageR = useCallback(
        (str: any) => {
            if (str) {
                const data: any = {
                    zh_CN: "简体中文",
                    en_US: "English",
                    zh_TW: "繁體中文"
                }
                return data[str];
            }

        },
        [],
    )

    const ButtonList: FC = () => {
        const changeLanguage = useCallback(
            (language: Language) => {

                // i18n.changeLanguage(language);
                setLocale(language)
                setLocales(language);
                localStorage.setItem("language", language);
                staticLanguage$.next(language)
            },
            [],
        )
        return (
            <div className='button-list'>
                <Button onClick={() => changeLanguage("en_US")} className='button-list-btn' type='link'>{"English"}</Button>
                <Button onClick={() => changeLanguage("zh_CN")} className='button-list-btn' type='link'>{"简体中文"}</Button>
                <Button onClick={() => changeLanguage("zh_TW")} className='button-list-btn' type='link'>{"繁體中文"}</Button>
            </div>
        )
    }
    return (
        <div className="navigation">
            <Row>
                <Col span={userInfo?.id ? 12 : 15}>
                    <p className='navigation-title' onClick={goHome}>
                        <img src={logo} alt="" />
                    </p>
                </Col>
                <Col span={userInfo?.id ? 0 : 5}>
                    <div className='navigation-language-box'>
                        <div className='navigation-language'>
                            <Dropdown dropdownRender={() => <ButtonList />}>
                                <Button style={{ color: '#878CB1' }} color='#878CB1' type='link'>
                                    {languageR(locale)}
                                </Button>
                            </Dropdown>
                        </div>
                    </div>
                </Col>

                <Col span={userInfo?.id ? 12 : 4}>
                    <div className='navigation-user'>
                        {userInfo && userInfo.id 
                            ?<UserOutlined onClick={goUser} className='navigation-user-icon' style={{ fontSize: 20 }} />
                            :<div className='navigation-login-box' onClick={goUser}>Login</div>
                        }
                        {/* <UserOutlined onClick={goUser} className='navigation-user-icon' style={{ fontSize: 20 }} />
                         */}
                        
                        {userInfo && userInfo.id &&
                            (<p className='navigation-user-logged'>
                                <BellOutlined className='navigation-user-logged-icon' />
                            </p>)
                        }
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Navigation;