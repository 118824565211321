import "lib-flexible";
import { ComponentType, FC, ReactNode, Suspense, lazy, useCallback, useEffect, useState } from "react";
import { BrowserRouter, Navigate, useRoutes } from "react-router-dom";
// import SuspenseUi from "../../components/SuspenseUi";
import LoadingPlx from "@/components/loadingPlx.tsx";
import { useLocale } from "@/polyglotplug";
import { get } from "@/server/api";
import { TOKEN_KEY } from "../../store/account/service";
import H5MadelPlx from "./components/H5MadelPlx";
import "./index.less";
import {
  IObjectRouter,
  filterRoutersConfig,
} from "./utils/filterRoutersConfig";
const Home = lazy(() => import("./views/Home"));
const Login = lazy(() => import("./views/Login"));
const Register = lazy(() => import("./views/Register"));
const Render = lazy(() => import("./views/Render"));
const Progress = lazy(() => import("./views/Progress"));
// const UpgradePlus = lazy(() => import('./views/UpgradePlus'));
// const RenderStart = lazy(() => import("./views/RenderStart"));
const ReaderView = lazy(() => import("./views/ReaderView"));
const RegistrationSuccessful = lazy(
  () => import("./views/RegistrationSuccessful")
);
const EmailBinding = lazy(() => import("./views/EmailBinding"));
// const PaymentInformation = lazy(() => import('./views/PaymentInformation'));
// const PlusView = lazy(() => import('./views/PlusView'));
const Authentication = lazy(() => import("./views/Authentication"));
const Preference = lazy(() => import("./views/Preference"));
const User = lazy(() => import("./views/User"));
const MoreHistory = lazy(() => import("./views/MoreHistory"));
// const MyBack = lazy(() => import('./views/MyBack'));
// const AddCard = lazy(() => import('./views/AddCard'));
const ResetPassword = lazy(() => import("./views/ResetPassword"));
const CreateNewPassword = lazy(() => import("./views/CreateNewPassword"));
// const Bill = lazy(() => import('./views/Bill'));
const LoginCode = lazy(() => import("./views/LoginCode"));
const TermsAndConditions = lazy(() => import("./views/TermsAndConditions"));

const Members = lazy(() => import("./views/Members"));
const OrdinaryMembers = lazy(() => import("./views/OrdinaryMembers"));
const MembersCredit = lazy(() => import("./views/MembersCredit"));
const MembersInvite = lazy(() => import("./views/MembersInvite"));
const Credit = lazy(() => import("./views/Credit"));
const OrdinaryCredit = lazy(() => import("./views/OrdinaryCredit"));
const AssociationCode = lazy(() => import("./views/AssociationCode"));
const ApiKey = lazy(() => import("./views/ApiKey"));
const CreateToken = lazy(() => import("./views/CreateToken"));
const ViewToken = lazy(() => import("./views/ViewToken"));

const MobilePage: FC = () => {
  const t = useLocale();
  const [open, setOpen] = useState<boolean>(false);
  const [noticeText, setNoticeText] = useState<string>("");

  // 在App组件中保存用户登录状态，初始值为false
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(() =>
    localStorage.getItem(TOKEN_KEY) ? true : false
  );
  // 定义路由守卫函数，用于检查用户是否已经登录
  const requireAuth = (Component: ComponentType<{}>): ReactNode => {
    if (typeof isLoggedIn === "boolean") {
      return isLoggedIn ? (
        <Component />
      ) : (
        <Navigate to="/login" replace={true} />
      );
    } else {
      return <Navigate to="/login" replace={true} />;
    }
  };
  const routes: IObjectRouter[] = [
    { path: "/home", element: <Home /> },
    {
      path: "/login",
      element: <Login setIsLoggedIn={setIsLoggedIn} />,
      layout: false,
    },
    { path: "/register", element: <Register />, layout: false },
    { path: "/render", element: <Render />, layout: false },
    { path: "/progress", element: <Progress />, layout: false },
    // { path: "/upgrade-plus", element: requireAuth(UpgradePlus)},
    // { path: "/render-start", element: <RenderStart />, layout: false },
    { path: "/render-view", element: <ReaderView />, layout: false },
    {
      path: "/registered-successfully",
      element: <RegistrationSuccessful />,
      layout: false,
    },
    { path: "/email-binding", element: <EmailBinding />, layout: false },
    // { path: "/payment-information", element: requireAuth(PaymentInformation),layout: false },
    // { path: "/plus-view", element: requireAuth(PlusView),layout: false},
    {
      path: "/authentication",
      element: requireAuth(Authentication),
      layout: false,
    },
    { path: "/preference", element: requireAuth(Preference), layout: false },
    { path: "/user", element: requireAuth(User), layout: false },
    { path: "/more-history", element: requireAuth(MoreHistory), layout: false },
    // { path: "/my-back", element: requireAuth(MyBack),layout: false },
    // { path: "/add-card", element: requireAuth(AddCard),layout: false },
    { path: "/reset-password", element: <ResetPassword />, layout: false },
    {
      path: "/create-new-password",
      element: <CreateNewPassword />,
      layout: false,
    },
    // { path: "/bill", element: requireAuth(Bill),layout: false },
    { path: "/login-code", element: requireAuth(LoginCode), layout: false },
    { path: "/terms_and_conditions", element: <TermsAndConditions /> },
    { path: "/members", element: requireAuth(Members), layout: false },
    { path: "/ordinary-members", element: requireAuth(OrdinaryMembers), layout: false }, // 订阅账号团队页面
    { path: "/members-credit", element: requireAuth(MembersCredit), layout: false },
    { path: "/members-invite", element: requireAuth(MembersInvite), layout: false },
    { path: "/credit", element: requireAuth(Credit), layout: false },
    { path: "/ordinary-credit", element: requireAuth(OrdinaryCredit), layout: false },
    { path: "/association_code/:associationCode", element: <AssociationCode />, layout: false },
    { path: "/token", element: <ApiKey />, layout: false },
    { path: "/create-token", element: <CreateToken />, layout: false },
    { path: "/view-token", element: <ViewToken />, layout: false },

    { path: "*", element: <Navigate to="/home" replace /> },
  ];
  const IRoutes = () => useRoutes(filterRoutersConfig(routes));

  const getNotice = useCallback(
    async () => {
      let response: any = await get(`/info/app`);
      if (response.code === 200) {
        if (response.data.criticalNoticeTmplStatus === "1") {
          setNoticeText(response.data.criticalNoticeTmplContent);
          setOpen(true);
        }
      }

    },
    [],
  )
  useEffect(() => {
    getNotice();

  }, [getNotice])

  // const getToken = useCallback(
  //   async () => {
  //     let response: any = await post(`/user/token/renewal`);
  //     if (response.code === 200) {
  //       localStorage.setItem(TOKEN_KEY, response.data)
  //     } else {
  //       localStorage.removeItem(TOKEN_KEY);
  //       window.location.href = `${window.location.origin}/login`;
  //     }
  //   },
  //   [],
  // )

  // useEffect(() => {
  //   getToken()
  // }, [getToken])

  return (
    <div className="mobile-page">
      <BrowserRouter>
        <Suspense fallback={<LoadingPlx />}>
          <H5MadelPlx />
          <IRoutes />
        </Suspense>
      </BrowserRouter>
      {open &&
        <div className="bulletin-moblie-box-fixed">
          <div className="bulletin-box-mobile">
            <div className="bulletin">
              <img src={require("../../img/notice_h5.png")} alt="" />
              <p>{noticeText}</p>
              <span>{t("SYSTEM_MAINTENANCE_NOTICE")}</span>
            </div>
          </div>
        </div>}
    </div>
  );
};
export default MobilePage;
