import { ConfigProvider } from "antd";
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import ReactGA from "react-ga4";
import "./App.css";
import useWindowResize from "./Hooks/useWindowResize";
import { LanguageProvider } from "./components/LanguageContext";
import MobilePage from "./pages/h5";
import PCPage from "./pages/pc";
import { VERSION_NUMBER } from "./store/globalConfig/config";

function App() {
  ReactGA.initialize(VERSION_NUMBER);
  const rootFontSize = isMobile ? 16 : 16; // 判断设备类型设置根元素字体大小
  document.documentElement.style.fontSize = `${rootFontSize}px`;
  const handleWindowResize = () => {
    const rootFontSize = isMobile ? 16 : 16; // 判断设备类型设置根元素字体大小
    document.documentElement.style.fontSize = `${rootFontSize}px`;
  };
  useWindowResize(handleWindowResize);
  return (
    <div className="App">
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#77b2d3",
          },
        }}
      >
        <LanguageProvider>
          <MobileView>
            <MobilePage />
          </MobileView>
          <BrowserView>
            <PCPage />
          </BrowserView>
        </LanguageProvider>
      </ConfigProvider>
      
    </div>
  );
}

export default App;
