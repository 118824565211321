const tw = {
  "MAKE_THE": "讓設計",
  "DESIGN_EASIER": "變得更簡單",
  "UPLOAD_SPACE_PHOTO__CONFIGURE_RENDERING_PARAMETERS_1": "上傳空間照片 設置渲染參數",
  "UPLOAD_SPACE_PHOTO__CONFIGURE_RENDERING_PARAMETERS_2": "生成你的專屬設計",
  "GET_YOUR_DESIGN": "取得您的設計",
  "FREE_TRIAL": "免費試用",
  "ENJOY_COMPLIMENTARY_CREDITS_DAILY": "每天享受 {n} 個免費次數",
  "YOUR_FREE_QUOTA_FOR_TODAY_HAS_BEEN_USED_UP": "您今天的免費次數已用完",
  "REGISTER_ACCOUNT": "註冊賬戶",
  "CLAIM_100_PROFESSIONAL_RENDERING_CREDITS_FOR_FREE": "免費領取 100 個專業級渲染次數！",
  "FOR_MORE_QUESTIONS_PLEASE_CONTACT": "更多問題，請聯系客服",
  "LOGIN_IN": "登入",
  "STYLE": "風格",
  "JAPANESE_DESIGN": "日式設計",
  "MINIMALIST": "極簡風格",
  "ART_NOUVEAU": "新藝術",
  "BOHEMIAN": "波西米亞",
  "INDUSTRIAL": "工業",
  "SCANDINAVIAN": "北歐風",
  "BAROQUE": "巴洛克",
  "COTTAGECORE": "田園風",
  "MAXIMALIST": "極致風格",
  "MIDCENTURY_MODERN": "中世紀現代",
  "NEOCLASSIC": "新古典主義",
  "VINTAGE": "復古",
  "BIOPHILIC": "親自然",
  "TROPICAL": "熱帶",
  "ZEN": "禪意",
  "COASTAL": "海岸",
  "FARMHOUSE": "農舍",
  "FRENCH_COUNTRY_DESIGN": "法式鄉村",
  "RUSTIC": "鄉村風",
  "SKI_CHALET": "滑雪小屋",
  "TRIBAL": "部落",
  "ART_DECO": "裝飾藝術",
  "GAMING_ROOM": "遊戲房間",
  "CHINESE_NEW_YEAR": "中國新年",
  "CHRISTMAS": "聖誕節",
  "EASTER": "復活節",
  "HALLOWEEN": "萬聖節",
  "CYBERPUNK": "賽博朋克",
  "ROOM": "房間",
  "OFFICE": "辦公室",
  "WORKSHOP": "工作室",
  "MEETING_ROOM": "會議室",
  "COWORKING_SPACE": "共享辦公空間",
  "RESTAURANT": "餐廳",
  "COFFEE_SHOP": "咖啡廳",
  "CLOTHING_STORE": "服裝店",
  "FITNESS_GYM": "健身房",
  "TOILET": "洗手間",
  "HOTEL_LOBBY": "酒店大堂",
  "HOTEL_ROOM": "酒店客房",
  "HOTEL_BATHROOM": "酒店浴室",
  "EXHIBITION_SPACE": "展覽空間",
  "ONSEN": "溫泉",
  "LIVING_ROOM": "客廳",
  "DINING_ROOM": "餐廳",
  "BEDROOM": "臥室",
  "CHILDREN_ROOM": "兒童房",
  "KITCHEN": "廚房",
  "BATH_ROOM": "浴室",
  "STUDY_ROOM": "學習室",
  "HOME_OFFICE": "家庭辦公室",
  "ATTIC": "閣樓",
  "WALK_IN_CLOSET": "步入式衣帽間",
  "MUDROOM": "儲物室",
  "DROP_ZONE": "閑置區",
  "OUTDOOR_PATIO": "室外庭院",
  "OUTDOOR_GARDEN": "室外花園",
  "OUTDOOR_POOL_AREA": "室外遊泳區域",
  "HOUSE_EXTERIOR": "房屋外觀",
  "MODE": "模式",
  "VIRTUAL_STAGING": "虛擬層次",
  "INTERIOR_DESIGN": "室內設計",
  "RESOLUTION": "尺寸",
  "LOW": "高",
  "HIGH": "低",
  "GENERATE": "生成",
  "RENDER_FAILURE_TIP:INSUFFICIENT_NUMBER_OF_RENDERINGS": "渲染失敗提示：渲染次數不足",
  "RENDERING": "渲染中...",
  "CANCEL": "取消",
  "TIPS": "提示",
  "DO_YOU_STILL_WANT_TO_CANCEL": "渲染中。如果您選擇取消，當前的渲染次數將不會退還，仍會被扣除。您還想取消嗎？",
  "CONFIRM": "確認",
  "CREATED_TIME": "創建時間",
  "ROOM_COWORKING_SPACE": "風格: 共享辦公空間",
  "MODE_INTERIOR_DESIGN": "模式: 室內設計",
  "RESOLUTION_HIGH": "尺寸: 高",
  "STYLE_CYBERPUNK": "風格: 賽博朋克",
  "SHARE_LINK": "分享鏈接",
  "COPY": "復制",
  "TODAY": "今天",
  "YESTERDAY": "昨天",
  "PREVIOUS_7_DAYS": "7天之内",
  "PREVIOUS_30_DAYS": "30天之内",
  "EARLIER": "更早",
  "RENDER_AGAIN": "再次渲染",
  "ARE_YOU_SURE_YOU_WANT_TO_RENDER_AGAIN": "你確定要再次渲染嗎？",
  "TWO_FACTOR_AUTHENTICATION_ENABLED_DISABLED": "啟用/禁用雙因素身份驗證",
  "ENGLISH": "英語",
  "LOGOUT": "退出登錄",
  "PREFERENCE": "偏好設置",
  "AUTHENTICATION": "認證",
  "MEMBERS": "會員",
  "LOGIN_HISTORY": "登錄歷史",
  "AVAILABLE_CREDIT": "次數",
  "EMAIL:": "郵箱:",
  "PASSWORD": "密碼",
  "REVISE": "修改",
  "OLD_PASSWORD": "舊密碼",
  "NEW_PASSWORD": "新密碼",
  "YOUR_NEW_PASSWORD": "您的新密碼",
  "CONFIRM_PASSWORD": "確認密碼",
  "SAVE": "保存",
  "RESET": "取消",
  "PLEASE_COMPLETE_THE_INFORMATION": "請將信息填寫完整！",
  "PLEASE_ENTER_YOUR_OLD_PASSWORD": "請輸入您的舊密碼",
  "PLEASE_ENTER_YOUR_NEW_PASSWORD": "請輸入您的新密碼",
  "PLEASE_CONFIRM_YOUR_NEW_PASSWORD": "請確認您的新密碼",
  "PASSWORD_COMPLEX_INFO": "密碼必須為 8-16 個字符，並包含數字和字母/特殊字符",
  "THE_TWO_PASSWORDS_YOU_ENTERED_DO_NOT_MATCH": "確認密碼不匹配，請重新輸入",
  "LANGUAGE": "語言",
  "ACTIVITIES": "活動",
  "NO_DATE": "-",
  "AUTOMATIC_ALLOCATION_FOR_NEW_ACCOUNT": "新賬戶自動分配",
  "VERSION": "版本",
  "TOP_UP": "充值",
  "PLEASE_ENTER_INVITE_EMAIL": "請輸入邀請電子郵件",
  "INVITATIONS": "邀請",
  "THE_ENTERED_EMAIL_IS_INVALID": "輸入的電子郵件無效",
  "EMAIL_FORMAT_INVALID": "電子郵件格式無效，請檢查後重試",
  "INVITEE_CANNOT_BE_INVITER": "被邀請者不能成為邀請者，請檢查後重試",
  "YOUR_REFERRAL_LINK": "您的推薦鏈接",
  "COPY_LINK": "復製鏈接",
  "TWO_FACTOR_AUTHENTICATION": "雙因素身份驗證",
  "USING_2FA": "*使用一次性密碼驗證器 (2FA)，在手機或電腦上啟用雙因素身份驗證功能",
  "NOTICE": "註意事項",
  "PLEASE_INSTALL_A_SOFTWARE_TOKEN_INSTALLER_AUTHENTICATOR_INFO": "請從應用程序庫中安裝軟件令牌安裝程序，如 Google Authenticator，然後掃描此二維碼，更多信息請參閱用戶手冊。",
  "CANNOT_SCAN_THE_QR_CODE_ADD_MANUALLY_PART_1": "無法掃描二維碼？",
  "CANNOT_SCAN_THE_QR_CODE_ADD_MANUALLY_PART_2": "要手動添加條目，請在移動應用程序中提供以下信息",
  "CANNOT_SCAN_THE_QR_CODE_ADD_MANUALLY_PART_3": "帳戶： greypanel",
  "CANNOT_SCAN_THE_QR_CODE_ADD_MANUALLY_PART_4": "密鑰：",
  "CANNOT_SCAN_THE_QR_CODE_ADD_MANUALLY_PART_5": "驗證方法： 基於時間的驗證",
  "PIN_CODE": "密碼",
  "REGISTER_WITH_THE_DUAL_AUTHENTICATION_APPLICATION": "註冊雙重認證應用程序",
  "SEARCH": "搜索",
  "INVITE_YOUR_FRIENDS": "邀請好友",
  "NAME": "名稱",
  "TRIGGER_TIME": "觸發時間",
  "ACTION": "操作",
  "NO_DATA": "無數據",
  "CREDITS": "積分",
  "ASSOCIATION": "關聯",
  "DISASSOCIATE": "解除關聯",
  "ASSOCIATE_MEMBERS": "準會員",
  "RECEIVED_CREDITS": "收到的積分",
  "BROWSER": "瀏覽器",
  "ACCESS_ON": "開啟訪問",
  "LOGIN_IP": "登錄 IP",
  "WELCOME_BACK_PLEASE_LOGIN_TO_YOUR_ACCOUNT": "歡迎回來！請登錄您的賬戶。",
  "ACCOUNT": "賬戶",
  "PLEASE_ENTER_YOUR_EMAIL": "請輸入您的電子郵件",
  "PLEASE_ENTER_YOUR_PASSWORD": "請輸入密碼",
  "REMEMBER_ME": "記住我的密碼",
  "FORGOT_PASSWORD": "忘記密碼",
  "LOGIN": "登錄",
  "HAVE_NO_ACCOUNT": "還沒有賬戶？",
  "SIGN_UP_HERE": "在此註冊。",
  "FOR_MORE_INFORMATION_ABOUT_KAINO_AI_ACCOUNT": "有關 KainoAI 賬戶的更多信息、",
  "CLICK_HERE": "點擊此處。",
  "SIGN_IN_WITH_A_THIRD_PARTY": "使用第三方登錄",
  "SIGN_IN_WITH_APPLE": "使用 Apple 登錄",
  "SIGN_IN_WITH_GOOGLE": "使用谷歌登錄",
  "SIGN_IN_WITH_FACEBOOK": "使用 Facebook 登錄",
  "PRIVACY_POLICY_PART_1": "我們致力於保護您的隱私。KainoAI 使用您提供給我們的信息，就我們的相關內容、產品和服務與您聯系。您可以隨時取消訂閱這些信息。更多信息，請查看我們的",
  "PRIVACY_POLICY": "隱私政策",
  "LOGIN_FAILED_ERROR_MESSAGE_THE_USER_IS_NOT_ACTIVATED": "登錄失敗錯誤信息：用戶未激活，請聯系管理員尋求幫助",
  "LOGIN_FAILED_ERROR_MESSAGE_THE_ACCOUNT_NEED_MODIFY_PASSWORD_AFTER_THIRD_PARTY_LOGIN": "登錄失敗錯誤信息：第三方登錄後，賬戶需要修改密碼",
  "LOGIN_FAILED_ERROR_MESSAGE_USERNAME_OR_PASSWORD_IS_INCORRECT": "登錄失敗錯誤消息：用戶名或密碼不正確，請檢查後重試",
  "RESET_PASSWORD": "重置密碼",
  "ENTER_THE_EMAIL_ASSOCIATED_TO_RESET_YOUR_PASSWORD": "輸入與您的帳戶相關聯的電子郵件，我們將發送一封電子郵件，說明如何重置密碼。",
  "EMAIL": "郵件",
  "RESEND_INSTRUCTIONS": "重新發送說明",
  "BACK": "返回",
  "PLEASE_SIGN_UP_YOUR_ACCOUNT": "請註冊您的帳戶。",
  "E_MAIL": "郵件",
  "YOUR_PASSWORD": "密碼",
  "SIGN_UP": "註冊",
  "ALREADY_HAVE_AN_ACCOUNT": "已經有賬戶？",
  "LOGIN_HERE": "在此登錄。",
  "PLEASE_ENTER_VALID_EMAIL": "輸入的電子郵件無效",
  "PLEASE_CONFIRM_YOUR_PASSWORD": "請確認密碼",
  "THE_TWO_PASSWORDS_YOU_ENTERED_DO_NOT_MATCH,_PLEASE_CHECK_AND_RESUBMIT": "您輸入的兩個密碼不匹配，請檢查並重新提交",
  "REGISTRATION_SUCCESSFUL": "註冊成功！",
  "WE_HAVE_SENT_EMAIL_TO_YOU": "謝謝。我們已經向您發送了電子郵件。",
  "PLEASE_CLICK_ON_THE_LINK_IN_THAT_EMAIL_TO_ACTIVATE_YOUR_ACCOUNT": "請點擊郵件中的鏈接激活您的賬戶。",
  "PLEASE_CLICK_THE_BUTTON_BELOW_TO_RESEND": "如果您沒有收到電子郵件，請點擊下面的按鈕重新發送。",
  "RESEND": "重新發送",
  "WELCOME_TO_LOGIN_YOUR_KAINO_AI_ACCOUNT": "歡迎登錄您的 KainoAI 賬戶",
  "SUCCESSFUL_LOGIN": "成功登錄",
  "TWO_FACTOR_AUTHENTICATION_2FA": "雙因素驗證 (2FA)",
  "A_MESSAGE_CONTAINING_A_VERIFICATION_CODE_HAS_BEEN_SENT_TO_YOUR_GOOGLE_AUTHENTICATOR": "包含驗證碼的信息已發送到您的 Google Authenticator",
  "6_DIGIT_SECURITY_CODE_TO_CONTINUE": "請輸入 6 位安全代碼以繼續",
  "VERIFY": "驗證",
  "CAMERA":"拍照",
  "PHOTO_LIBRARY":"相簿",
  "PASSWORD_CANNOT_BE_THE_SAME":"新舊密碼不能一致",
  "CLOSE_ACCOUNT":"註銷帳號",
  "SURE_TO_DELETE_ACCOUNT":"您確定要刪除您的帳戶嗎?這將永久刪除您的帳戶。",
  "DELETE_IMAGE":"刪除圖片",
  "SURE_TO_DELETE_IMAGE":"你確定要刪除這張圖片嗎?",
  "SURE_TO_LOGOUT":"確定要登出嗎?",
  "NO_CREDIT":"次數已用完",
  "NO_CREDIT_DESC": "您今日的免費額度已經用完，註冊帳號可獲得更多免費次數，是否進行註冊？",
  "START_RENDER":"開始",
  "ORIGINAL":"原圖",
  "NEW":"渲染圖",
  "ANNOUNCEMENT":"公告",
  "NEW_VERSION_AVAILABLE":"新版本可用",
  "UPDATE":"更新",
  "ASSOCIATE_SUCCESS":"關聯成功",
  "AGREE_BUILD_ASSOCIATION":"確定同意 {email} 的建立關聯申請嗎？",
  "AFTER_AGREE_ASSOCIATION":"同意後將與對方組織建立關聯，對方可以為你指派Credits",
  "DISABLE_2FA":"確定關閉雙重認證功能？",
  "GET_LOGIN_CODE":"從 Google Authenticator 取得驗證碼。",
  "NEXT":"下一步",
  "ENTERCODE":"輸入驗證碼",
  "TWOFA_ON":"雙認證已啟用",
  "ADDITIONAL_CODE": "當您在我們不認識的裝置上登入時，除密碼外，我們也會要求您輸入驗證碼。",
  "DONE":"完成",
  "AVAILABLE_CREDITS":"可用次數",
  "SURPLUS_CREDIT":"剩餘次數",
  "SPENT":"已使用",
  "ALL":"全部",
  "CHOOSE_MEMBER":"選擇會員",
  "INVITE_MEMBER":"邀請會員",
  "CHOOSE_CREDITS":"選擇次數",
  "ISSUE_CREDITS":"分發 {n} 次",
  "MULTIPLES_OF_100_CAN_BE_ALLOCATED_EACH_TIME":"*每次可分配的數量為100的倍數",
  "NO_ENOUGH_CREDITS_CANNOT_ASSOCIATION":"剩餘次數不足，無法成功分配",
  "EMAIL_SENT":"郵件已發送",
  "EMAIL_SENT_DES1":"謝謝，我們已經將郵件寄送",
  "EMAIL_SENT_DES2":"請透過您的信箱驗證修改密碼",
  "EMAIL_SENT_DES3":"如果您沒有收到郵件，請點擊重新發送",
  "RESEND_COOLING":"{n}秒後重新發送",
  "USERNAME_OR_PASSWORD_CANNOT_BE_EMPTY":"使用者名稱或信箱不能為空",
  "MAILBOX_NOT_ACTIVATED":"郵箱未啟動, 請啟動後再嘗試",
  "SEND_VERIFICATION_EMAIL_AGAIN":"重新發送驗證郵件",
  "PRIVACY_LONG_TEXT": "我們致力於保護您的隱私。KainoAI會使用您提供給我們的信息，就我們的相關內容、產品和服務與您聯繫。您可以隨時取消訂閱這些信息。更多信息，請查看 我們的",
  "PRIVACY_LONG_LINK":"隱私協定",
  "AGENT":"代理",
  "RECEVED_CREDITS":"已取得次數",
  "COPY_SUCCESS":"複製成功",
  "DATA_PRIVACY_MARKETING_POLICYKAINOAI":"資料隱私與行銷政策 - KainoAI",
  "WE_ARE_COMMITTED_TO_ENSURING_THE_PRIVACY_AND_SECURITY_OF_YOUR_PERSONAL_INFORMATION": "我們致力於確保您個人資訊的隱私和安全。本隱私權政策概述了我們如何收集、使用和保護您的資料。透過使用我們的服務，您同意接受此處所述的條款和做法。 ",
  "INFORMATION_WE_COLLECT":"1. 我們收集的資料",
  "WE_MAY_COLLECT_THE_FOLLOWING_TYPES_OF_INFORMATION": "我們可能會收集以下類型的資訊：",
  "PERSONAL_INFORMATION_NAME_EMAIL_ADDRESS_CONTACT_DETAILS": "個人資料：姓名、電子郵件地址、聯絡方式。",
  "DESIGN_PREFERENCES_INFORMATION_YOU_PROVIDE_REGARDING_YOUR_DESIGN_NEEDS_AND_PREFERENCES": "設計偏好：您提供的有關設計需求和偏好的資訊。",
  "USAGE_DATA_INTERACTION_DATA_SUCH_AS_YOUR_INTERACTIONS_WITH_OUR_WEBSITE_AND_DESIGN_TOOLS": "使用數據：互動數據，例如您與我們的網站和設計工具的互動。",
  "WE_COLLECT_THIS_INFORMATION_TO_DELIVER_PERSONALIZED_AND_EFFICIENT_GRAPHIC_DESIGN_SOLUTIONS_TO_YOU": "我們收集此資訊以向您提供個人化和高效的圖形設計解決方案。",
  "HOW_WE_USE_YOUR_INFORMATION":"2. 我們如何使用您的資訊",
  "WE_USE_YOUR_INFORMATION_TO":"我們使用您的資料：",
  "CREATE_AND_DELIVER_GRAPHIC_DESIGNS_BASED_ON_YOUR_PREFERENCES": "基於您的偏好建立和交付圖形設計。",
  "COMMUNICATE_WITH_YOU_ABOUT_YOUR_DESIGN_PROJECTS_AND_UPDATES": "與您溝通有關您的設計專案和更新的資訊。",
  "IMPROVE_OUR_SERVICES_AND_OFFERINGS_BASED_ON_USER_INTERACTIONS": "使用數據：互動數據，例如您與我們的網站和設計工具的互動。",
  "DATA_SECURITY":"3. 資料安全性",
  "WE_TAKE_THE_SECURITY_OF_YOUR_DATA_SERIOUSLY": "我們認真對待您資料的安全性。我們採取技術和組織措施，以防止您的資訊遭到未經授權的存取、遺失、濫用或更改。然而，請注意，透過網路傳輸資料的 任何方法都不是完全安全的。",
  "SHARING_OF_INFORMATION":"4. 資訊分享",
  "WE_DO_NOT_SHARE_YOUR_PERSONAL_INFORMATION_WITH_THIRD_PARTIES_FOR_MARKETING_PURPOSES": "我們不會為行銷目的與第三方分享您的個人資訊。但是，我們可能會與以下方分享您的資訊：",
  "OUR_SERVICE_PROVIDERS_WHO_ASSIST_US_IN_DELIVERING_OUR_DESIGN_SERVICES": "協助我們提供設計服務的服務提供者。",
  "LEGAL_AND_REGULATORY_AUTHORITIES":"根據法律要求的法律和監管當局。",
  "COOKIES_AND_TRACKING_TECHNOLOGIES":"5. Cookie與追蹤技術",
  "OUR_WEBSITE_AND_SERVICES_MAY_USE_COOKIES": "我們的網站和服務可能使用Cookie和類似的追蹤技術來增強您的瀏覽體驗並收集使用資訊。您可以調整瀏覽器設定以管理Cookie偏好。",
  "YOUR_CHOICES":"6. 您的選擇",
  "YOU_HAVE_THE_RIGHT_TO":"您有以下權利：",
  "ACCESS_CORRECT_OR_DELETE_YOUR_PERSONAL_INFORMATION": "存取、更正或刪除您的個人資訊。",
  "OPT_OUT_OF_RECEIVING_MARKETING_COMMUNICATIONS":"選擇不接收行銷通訊。",
  "RAISE_OBJECTIONS_OR_RESTRICT_THE_PROCESSING_OF_YOUR_DATA": "提出異議或限制資料處理。",
  "TO_EXERCISE_THESE_RIGHTS_PLEASE_CONTACT_US_AT":`要行使這些權利，請透過$enquiry@kainoai.com與我們聯絡。 `,
  "CHILDREN_PRIVACY":"7. 兒童隱私",
  "OUR_SERVICES_ARE_NOT_INTENDED_FOR_INDIVIDUALS_UNDER_THE_AGE_OF": "我們的服務不面向[12]歲以下的個人。我們不會故意收集兒童的個人資料。",
  "CHANGES_TO_PRIVACY_POLICY":"8. 隱私權政策的變更",
  "WE_MAY_UPDATE_THIS_PRIVACY_POLICY_PERIODICALLY_TO_REFLECT_CHANGES_IN_OUR_PRACTICES_AND_SERVICES": "我們可能定期更新本隱私權政策，以反映我們的做法和服務的變更。修訂版本將與生效日期一起發佈在我們的網站上。",
  "FOR_ANY_INQUIRIES_ABOUT_OUR_PRIVACY_POLICY_OR_DATA_PRACTICES":`有關我們的隱私權政策或資料實務的任何查詢，請透過enquiry@kainoai.com與我們聯絡。 您的隱私對我們很重要，我們致力於解決您可能有的任何疑慮。 `,
  "KAINOAI_OPERATION_TEAM":"KainoAI營運團隊",
  "CONTACT_EMAIL":"聯絡電子郵件：",
  "TWOFA":"雙驗證",
  "HISTORY":"歷史記錄",
  "MORE":"更多",
  "NOW":" ", //中文狀態為空字元 英文狀態翻譯為now
  "SAVE_SUCCESS":"保存成功",
  "TASK_EXECUTED_SUCCESSFULLY":"渲染完成",
  "TASK_EXECUTION_FAILURE":"渲染失敗",
  "UUID_INVALID":"uuid無效",
  "RENDER_SUCCESS":"渲染成功",
  "CANCEL_RENDER": "渲染進行中, 如果您選擇取消, 目前渲染的次數不會返還, 會依然扣除。您確定要繼續執行取消操作嗎？",
  "SELECT_STYLE":"請選擇一個風格",
  "SELECT_ROOM":"請選擇一個房間類型",
  "CHANGE_PICTURE":"替換照片",
  "CHOOSE_STYLE":"選擇風格",
  "CHOOSE_ROOM":"選擇房間",
  "EMPTY":"空蕩蕩的...",
  "PROFILE":"個人首頁",
  "RENDER_HISTORY":"渲染歷史",
  "RENDER_DETAIL":"渲染詳情",
  "PREVIEW":"預覽",
  "DELETE_CONFIRM_TEXT1":"確認刪除所選內容嗎？此操作不可撤銷。",
  "DELETE_CONFIRM_TEXT2":"確認刪除{name}嗎？此操作不可撤銷。",
  "API_KEYS":"API 金鑰",
  "NOT_HAVE_ANY_API_KEYS":"您目前沒有任何 API 金鑰",
  "CREATE_USING_THE_BUTTON":"使用下面的按鈕建立一個以開始",
  "SEARCH_NAME":"搜尋名稱",
  "CREATE_API_KEY":"建立 API 金鑰",
  "OPTIONAL":"可選的",
  "MY_TEST_KEY":"我的測試密鑰",
  "CREATE":"創建",
  "CREATE_API_KEY_SECURITY_DESCRIPTION":"請將此秘密金鑰保存在安全且可存取的地方，出於安全原因，您將無法透過您的KainoAI 帳戶再次查看它。如果您遺失了這個秘密金鑰，您將需要 生成一個新的。",
  "KEY":"密鑰",
  "LAST_USED":"最後使用時間",
  "SECRET_KEY": '秘鑰',
  "AUTH_METHOD": '認證方式',
  "SAVE_PASSWORD_2FA":'將您的 AID 密碼儲存到 Google Authenticator。 如果尚未儲存，請返回此畫面繼續下一步。 ',
  "FAIL_TO_LINK_APPLE_STORE":'無法連線到 Apple Store',
  "DROP_AN_IMAGE": "拖放圖片/點擊選擇",
  "RESIDENTIAL": "住宅",
  "COMMERCIAL": "商業",
  "UPLOAD_FAILED": "上傳失敗",
  "DELETE": "刪除",
  "GOOGLE_AUTH_DISABLED": "已關閉谷歌二次驗證",
  "GOOGLE_AUTH_ENABLED": "已開啟谷歌二次驗證",
  "BINDING_FAILED": "綁定失敗",
  "DOCUMENT_VERIFICATION_SUCCESSFUL": "文件驗證成功",
  "DEAR_USER": "親愛的用戶",
  "YOUR_ACCOUNT_HAS_BEEN_SUCCESSFULLY_VERIFIED": "恭喜，您的賬戶已成功驗證",
  "EMAIL_SENDING_FAILED": "郵件發送失敗",
  "AUTOMATICALLY_JUMP_AFTER_3_SECONDS": "3秒後自動跳轉到首頁，或者點擊按鈕立即跳轉",
  "AUTOMATICALLY_JUMP_AFTER_S": "s後自動跳轉",
  "JUMP_NOW": "立即跳轉",
  "VERIFICATION_FAILED": "驗證失敗",
  "VERIFICATION_HAS_EXPIRED":"出現了一些問題，該驗證已過期。我們已重新發送激活郵件到 您的郵箱，請註意查收。",
  "PARAMETER": "參數",
  "EMAIL_LENGTH_CANNOT_EXCEED_50_CHARACTERS": "郵件長度不能超過50個字符!",
  "UPLOAD_PICTURE": "上傳圖片",
  "DOWNLOAD": "下載",
  "FULL": "全視圖",
  "SENDING":"發送",
  "SUCCESSFUL_DISTRIBUTION": "分配成功",
  "STATUS":"狀態",
  "SYSTEM_MAINTENANCE_NOTICE":"系統維護通知",
  "DOES_IT_JUMP_TO_THE_ANDROID_BUNDLE": "是否跳轉到Android綁定?",
  "WEB_BINDING": "Web端綁定",
  "ANDROID_BINDING": "Android端綁定",
  "APPLY": "應用",
  "EDIT": "編輯",
  "THE_NAME_CANNOT_BE_EMPTY": "名稱不能為空",
  "CREATE_DATE": "創建日期",
  "ADVANCED_PROMPT": "高級提示词",
  "DONT_NEED_THIS_SERVICE": "不再需要使用該服務",
  "BAD_USE_EXPERIENCE": "使用者體驗不佳",
  "FOUND_A_BETTER_ALTERNATIVE": "找到了更好的替代品",
  "REMARK": "備註",
  "PLEASE_CHOOSE_REASON": "請選擇原因",
  "OBJECT_REMOVAL": "擦除",
  "RENDER_FAILURE_TIP":"渲染失敗提示",
  PLEASE_RATE_YOUR_SATISFACTION_WITH_THE_RENDERING_BY_KAINOAI:"請評價您對 KainoAI 渲染服務的滿意度。",
  VERY_UNSATISFIED:"非常不滿意",
  UNSATISFIED:"不滿意",
  NEUTRAL:"中性",
  SATISFIED:"滿意",
  VERY_SATISFIED:"非常滿意",
  SKETCH: "圖片轉線稿",
  UPLOAD:"上傳",
  ERASER:"擦除",
  AUTO:"AI 選區",
  AUTO_SELECTING_AN_ITEM_BY_CLICKING_ON_IT:"通過點擊讓AI自動選區",
  MANUAL:"手動選區",
  MANUAL_SELECTION_OF_ITEMS_BY_PAINTING:"通過手動選區",
  MULTIPLE_SELECTION:"多點選區",
  AUTOMATIC_ITEM_SELECTION_THROUGH_MULTIPLE_MARKER_POINTS:"通過多個標記點讓AI自動選區",
  CONFIRMATION_OF_CHOICE:"確認選擇",
  START: "開始",
  CONTRAST:"對比",
  REVOCATION:"撤銷",
  RECOVER:"恢復",
  BRUSH_SIZE:"畫筆大小",
  DRAG_AND_DROP_THE_IMAGE_HERE_TO_UPLOAD:"將圖片拖放到此處進行上傳",
}

export default tw;
