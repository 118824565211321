import { CONTACT_EMAIL } from "@/store/globalConfig/config";

export const en_US = {
  9999: "Failure of operation",
  10000:
    "Request parameter is invalid, please check the parameters before requesting again",
  10001: "Tokens is invalid, please try again after checking",
  10002: "Token is empty, please try again after checking",
  10003: "Token has expired, please try again after checking",
  10004: "Token failed, please contact the administrator for assistance",
  10100:
    "Fail to upload the picture, please contact the administrator for assistance",
  10101:
    "Image format is not supported, currently only supported formats: jpg, jpeg, png",
  10102:
    "Fail to query rendering progress, please contact the administrator for assistance",
  10103: "The render task is not exist",
  10104: "Render image selection invalid",
  10200: "Registration failure, the email has been used by others",
  10201:
    "Fail to create an user, please contact the administrator for assistance",
  10202:
    "Email does not exist, please contact the administrator for assistance",
  10203:
    "Username or Password is incorrect, please try it again after checking",
  10204: "Fail to log in, please contact the administrator for assistance",
  10205:
    "Fail to update the password, please contact the administrator for assistance",
  10206: "User does not exist",
  10207: "Email format invalid, please try again after checking",
  10208: "Fail to send email, please contact the administrator for assistance",
  10209:
    "Fail to activate email, please contact the administrator for assistance",
  10211: "The Token Has Expired, Email Activation Failed",
  10212:
    "Fail to login via facebook account, please contact the administrator for assistance",
  10213:
    "Fail to login via google account, please contact the administrator for assistance",
  10214:
    "Fail to perform Oauth's request, please contact the administrator for assistance",
  10215:
    "Fail to get the two-factors verification code, please contact the administrator for assistance",
  10216: "Two-factors verification failure, please try again after checking",
  10217: "Two-factors verification is disabled",

  10218: "Email has been activated",
  10219:
    "Email activation token is invalid, please contact the administrator for assistance",
  10220: "Fail to render the image, the subscription balance is insufficient",
  10221: "Recaptcha verification fails, please try again after checking",
  10222:
    "Fail to render the image, the subscription has expired, please contact the administrator for assistance",
  10223:
    "Fail to reset the password, please contact the administrator for assistance",
  10224: "Email is not activated",

  10225:
    "Login data is abnormal, please contact the administrator for assistance",
  10226: "Username is too long,The length cannot be more than 50",
  10227: "User logout failed",
  10228:
    "Fail to log in apple account, please contact the administrator for assistance",
  10229: "Fail to delete the user",
  10230:
    "The user is not activated, please contact the administrator for assistance",
  10231: "The account need modify pasword after third party login",
  10234: "The user type is not an agent type",
  10236:"Your login is restricted. Please try again after 15minutes",
  10300: "Trials Have Been Used Up, Please Log In",
  10400:
    "Fail to create the subscriptions, please contact the developer for assistance",
  10401:
    "Fail to create the payment method, please contact the developer for assistance",
  10402:
    "Fail to delete the payment method, please contact the developer for assistance",
  10500:
    "Fail to render the image, the subscription is empty, Please subscribe and use",
  10501: "Fail to subscribe ,the subscription is exist that in use currently",
  10502: "Fail to subscribe,the subscription is currently in progress",
  10503:
    "Abnormal subscription status, please contact the administrator for assistance",
  10504:
    "Current platform does not support the cancellation of the subscription, please contact the administrator for assistance",
  10505:
    "Current platform does not support the restoration of subscription, please contact the administrator for assistance",
  10600: "2fa not verified,please verification",
  10700:
    "Notice template ID is empty, please contact the administrator for assistance",

  10802: "Invitee cannot be inviter, please try again after checking",
  10804:
    "Invitation link does not work or does not exist, please check and try again.",
  10805:
    "Invitation link is invalid, email of current account does not match invited email",
  10806: "The account is bound to the Invitee's account.",
  10808:
    "Inviter has reached the maximum binding limit, please contact the inviter",
  10810:
    "Current account is agent account, cannot associate with other agent account",

  11000: "Platform is invalid, please contact the administrator for assistance",
  11001:
    "Render history not exist, please contact the administrator for assistance",

  HOME_PAGE: "Home Page",
  RENDER: "Render",
  SUBSCRIBE: "Subscribe",
  SETTINGS: "Settings",
  USER: "User",
  USER_INFO: "User Information",
  QUIT: "Quit",
  AID_MAKES_AI_CHANGE_LIFE: "KainoAI Makes Ai Change Life",
  YOU_CAN_UPLOAD_ROOM_PHOTOS:
    "You Can Upload Room Photos And Set Rendering Parameters, KainoAI Will Generate Ideal Room For You",
  GENERATE_MY_DREAM_ROOM: "Generate My Dream Room",
  LATEST_RENDERS: "New",
  DROP_AN_IMAGE: "Drop an image, tap to select",
  KEYWORDS: "Keywords",
  ROOM: "Room",
  MODE: "Mode",
  STYLE: "Style",
  RESOLUTION: "Resolution",
  HISTORY: "History",
  ONLY_JPG_PNG: "Only Upload Images In Jpg/Png Format.",
  TASK_EXECUTION_FAILURE: "Task Execution Failed.",
  RENDERING: "Rendering...",
  UUID_INVALID: "Uuid Is Invalid",
  TOKEN: "Token",
  TOTAL: "Total",
  TOKEN_USAGE_RECORDS: "Token Usage Records",
  CREATEDATETIME: "Created Time",
  USERID: "User Id",
  // RENDERING: "Rendering",
  IMAGE_GENERATION_RECORD: "Image Generation Record",
  SUCCESSFULLY_MODIFIED: "Modified Successfully",
  SUCCESSFULLY_SET: "Set Successfully",
  VERIFICATION_SUCCESSFUL: "Verification Succeeded",
  ACCOUNT_MANAGEMENT: "Account Management",
  CHANGE_PASSWORD: "Change Password",
  OLD_PASSWORD: "Old Password",
  NEW_PASSWORD: "New Password",
  CONFIRM_PASSWORD: "Confirm Password",
  YOUR_NEW_PASSWORD: "Your New Password",
  APPLY: "Apply",
  PLEASE_INPUT_YOUR_OLD_PASSWORD: "Please Enter Your Old Password",
  PLEASE_INPUT_YOUR_NEW_PASSWORD: "Please Enter Your New Password",
  THE_PASSWORD_MUST_BE_AT_LEAST_8:
    "The Password Must Be At Least 8 Characters Long",
  PLEASE_CONFIRM_YOUR_NEW_PASSWORD: "Please Confirm Your New Password",
  THE_TWO_PASSWORDS_DO_NOT_MATCH: "The Two Passwords You Entered Do Not Match",
  SECURITY_SETTINGS: "Security Settings",
  WHETHER_TO_ENABLE_TWO_FACTOR_AUTHENTICATION:
    "Whether To Enable Two-Factor Authentication:",
  SCAN_THE_QR_CODE_BELOW_TO_BIND: "Scan The Qr Code Below To Bind",
  CHECK: "Check",
  SYSTEM_LANGUAGE_SETTING: "System Language Setting",
  LANGUAGE: "Language",
  SIMPLIFIED_CHINESE: "Simplified Chinese",
  ENGLISH: "English",
  TRADITIONAL_CHINESE: "Traditional Chinese",
  UPLOADING_SUCCESSFULLY: "Uploaded Successfully",
  UPLOAD_FAILED: "Upload Failed",
  PLEASE_UPLOAD_THE_IMAGE: "Please Upload An Image",
  E_MAIL: "E-Mail",
  THE_INPUT_IS_NOT_VALID_E_MAIL: "The Entered Email Is Invalid",
  PLEASE_INPUT_YOUR_E_MAIL: "Please Enter Your Email",
  PLEASE_INPUT_YOUR_PASSWORD: "Please Enter Your Password",
  LOGIN: "Login",
  DO_NOT_HAVE_AN_ACCOUNT_YET: "Don't Have An Account Yet?",
  SIGN_UP_HERE: "Sign Up Here.",
  FOR_MORE_INFORMATION_ON_GREYPANE_ACCOUNTS:
    "For More Information About AI Accounts,",
  CLICK_HERE: "Click Here.",
  ACCOUNT_LOGIN: "Account Login",
  PASSWORD: "Password",
  SIGN_UP: "Sign Up",
  PLEASE_CONFIRM_YOUR_PASSWORD: "Please Confirm Your Password",
  YOUR_PASSWORD: "Your Password",
  ALREADY_AN_AID_ACCOUNT_HOLDER: "Already An KainoAI Account Holder?",
  LOGIN_HERE: "Log In Here.",
  EMAIL: "E-Mail",
  WELCOME_TO_LOG_IN_TO_YOUR_GREYPANEL_ACCOUNT:
    "Welcome To Log In To Your Stream Account",
  LOGIN_SUCCESSFUL: "Successful Login",
  DUAL_AUTHENTICATION: "Double Authentication (2FA)",
  A_MESSAGE_CONTAINING:
    "A Message Containing A Verification Code Has Been Sent To Your Google Authenticator",
  PLEASE_ENTER_THE_6_DIGIT_TWO_FACTOR:
    "Please Enter A 6-Digit Security Code To Continue",
  CONTINUE: "Continue",
  CANCEL: "Cancel",
  DOCUMENT_VERIFICATION_SUCCESSFUL: "Document Verification Successful",
  DEAR_USER: "Dear User",
  YOUR_ACCOUNT_HAS_BEEN_SUCCESSFULLY_VERIFIED:
    "Congratulations, Your Account Has Been Successfully Verified",
  CLICK_HERE_TO_LOGIN: "Click Here To Log In",
  VERIFICATION_FAILED: "Verification Failed",
  DOCUMENT_VALIDATION_FAILED: "Document Verification Failed",
  VERIFICATION_HAS_EXPIRED:
    "Some problems have occurred, and the verification has expired. We have resent the activation email to your mailbox, please check it.",
  MAIL_SENT_SUCCESSFULLY: "Email Sent Successfully",
  EMAIL_SENDING_FAILED: "Email Sending Failed",
  AUTOMATICALLY_JUMP_AFTER_3_SECONDS:
    "Automatically Jump To The Home Page After 3 Seconds, Or Click The Button To Jump Immediately",
  AUTOMATICALLY_JUMP_AFTER_S: "Automatic Jump",
  JUMP_NOW: "Jump Now",
  USE_A_THIRD_PARTY_LOGIN: "Sign In With A Third Party",
  LOG_IN_WITH_FACEBOOK: "Sign in with Facebook",
  LOG_IN_WITH_GOOGLE: "Sign in with Google",
  LOG_IN_WITH_APPLE: "Sign in with Apple",
  OPERATION_VALUE: "Operation Value",
  PREFERENCE: "Preference",
  AUTHENTICATION: "Authentication",
  LOGIN_HISTORY: "Login History",
  LAST_LOGIN_TIME: "Last Login Time",
  GOOGLE_AUTH_DISABLED: "Two-Factor Authentication Disabled",
  GOOGLE_AUTH_ENABLED: "Two-Factor Authentication Enabled",
  LOG_OUT: "Login Out",
  DELETE: "Delete",
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_GENERATED_RECORD:
    "Are You Sure You Want To Delete This Generated Record?",
  BILLING_CYCLE: "Billing Cycle",
  USED: "Used",
  STARTING_TIME: "Starting Time",
  REGISTRATION_TIME: "Registration Time",
  TWO_FACTOR_AUTHENTICATION: "Two Factor Authentication",
  ENABLE_TWO_FACTOR_AUTHENTICATION_ON_YOUR_PHONE:
    "*Enable Two-Factor authentication on your phone or computer using a one-time password authenticator (2FA)",
  PARAMETER: "Parameter",
  ACTION: "Action",
  VIEW: "View",
  BROWSER: "Browser",
  ACCESSON: "Access On",
  LOGIN_IP: "Login Ip",
  CREATE_NEW_PASSWORD: "Create New Password",
  PASSWORD_RESET_SUCCESSFUL: "Password Reset Successful",
  YOUR_NEW_PASSWORD_MUST_BE_DIFFERENT_FORM_PREVIOUS_USED_PASSWORDS:
    "Your New Password Must Be Different From The Previous Password.",
  RESET_PASSWORD_EMAIL_SENT_SUCCESSFULLY:
    "Reset Password Email Sent Successfully",
  RESET_PASSWORD: "Reset Password",
  ENTER_THE_EMAIL_ASSOCIATED_WITH_YOUR_ACCOUNT:
    "Enter The Email Associated With Your Account And We Will Send An Email With Instructions To Reset Your Password.",
  ONE_TIME_PAYMENT: "One-time payment",
  MONTHLY_PAYMENT: "Monthly payment",
  QUARTERLY_PAYMENT: "Quarterly payment",
  ANNUAL_PAYMENT: "Annual payment",
  FLOOR: "Floor",
  WALL: "Wall",
  CEILING: "Ceiling",
  YOUR_EMAIL_ADDRESS_IS_INCOMPLETE: "Your Email Address Is Incomplete",
  YOUR_BANK_CARD_NUMBER_IS_INCOMPLETE: "Your Bank Card Number Is Incomplete",
  THE_EXPIRY_DATE_OF_YOUR_CARD_IS_INCOMPLETE:
    "The Expiry Date Of Your Card Is Incomplete",
  THE_SECURITY_CODE_OF_YOUR_BANK_CARD_IS_INCOMPLETE:
    "The Security Code Of Your Bank Card Is Incomplete",
  YOUR_CARD_NAME_IS_INCOMPLETE: "Your Card Name Is Incomplete",
  SUBSCRIPTION_TYPE: "Subscription Type",
  SUBSCRIPTION_STATUS: "Subscription Status",
  SUBSCRIPTION_EXPIRATION_TIME: "Subscription Expiration Time",
  SUBSCRIPTION_START_TIME: "Subscription Start Time",

  AID_MAKES: "KainoAI Makes",
  AI_CHANGE_LIFE: "Ai Change Life",
  RENDERS: "Renders",
  UPGRADE_TO_PLUS: "Upgrade to Plus",
  WANT_UNLIMITED_USES: "Want Unlimited Uses?",
  QUICKLY: "Quickly",
  TESTER: "Free Trial",
  YOU_HAVE_3_FREE_TEST_OPPORTUNITIES: "You have 3 free test opportunities",
  PLUS: "Plus",
  UPGRADE_TO_PLUS_UNLIMITED_USE: "Upgrade to plus, unlimited use",
  FOR_MORE_QUESTIONS_PLEASE_CONTACT: "For more questions,please contact",
  SELECT_IMAGE: "Select Image",
  WELCOME_BACK_PLEASE_LOGIN_TO_YOUR_ACCOUNT:
    "Welcome back! Please login to your account.",
  REMEMBER_ME: "Remember me",
  FORGOT_PASSWORD: "Forgot password",
  PLEASE_SIGN_UP_YOUR_ACCOUNT: "Please Sign up your account.",
  ALREADY_A_AI_ACCOUNT_HOLDER: "Already a AI Account Holder?",
  REGISTRATION_SUCCESSFUL: " Registration Successful!",
  THANK_YOU_WE_HAVE_SENT_YOU_EMAIL_TO: "Thank you. We have sent you email",
  PLEASE_CLICK_ON_THE_LINK_IN_THAT_EMAIL_TO_ACTIVATE_YOUR_ACCOUNT:
    "Please click on the link in that email to activate your account.",
  IF_YOU_DID_NOT_RECEIVE_THE_EMAIL_PLEASE_CLICK_THE_BUTTON_BELOW_TO_RESEND:
    "If you did not receive the email, please click the button below to resend.",
  RESEND: "Resend",
  NEXT: "Next",
  SUBSCRIBE_TO_AI_PLUS: "Subscribe to AI Plus Subscription",
  PER: "per",
  MONTH: "month",
  AI_PLUS_SUBSCRIPTION: "AI Plus Subscription",
  BILLED_MONTHLY: "Billed monthly",
  SUBTOTAL: "Subtotal",
  TAX: "Tax",
  TOTAL_DUE_TODAY: "Total due today",
  THE_NUMBER_OF_FREE_TRIALS_HAS_BEEN_USED_UP:
    "The free trial number has been used up, you can choose to log in and continue to generate or go back to the home page and wait for the free trial number to refresh",
  GO_HOME: "Go Home",
  IMAGE_ADDRESS_HAS_BEEN_COPIED:
    "Image address has been copied, share with friends",
  RENDERS_IMAGE: "Renders Image",
  ORIGINAL_IMAGE: "Original Image",
  SHARE_WITH: "Share with",
  COPY: "Copy",
  CONGRATULATIONS_YOUR_ACCOUNT_HAS_BEEN_SUCCESSFULLY_VERIFIED:
    "Congratulations, Your Account Has Been Successfully Verified",
  AUTOMATICALLY_JUMP_TO_THE_HOME_PAGE_AFTER_3_SECONDS_OR_CLICK_THE_BUTTON_TO_JUMP_IMMEDIATELY:
    "Automatically Jump To The Home Page After 3 Seconds, Or Click The Button To Jump Immediately",
  AUTOMATIC_JUMP: "Automatic Jump",
  DOCUMENT_FAILED: "Document Failed",
  SOME_PROBLEMS_HAVE_OCCURRED:
    "Some problems have occurred, and the verification has verification has expired. We have resent the activation email to your mailbox, please check it.",
  BACK: "Back",
  ORDER_PAID_SUCCESSFULLY: "Order Paid Successfully",
  CONGRATULATIONS_FOR_BECOMING_A_PLUS_USER:
    "Congratulations for becoming a Plus user",
  CONGRATULATIONS_ON_OBTAINING_THE_FOLLOWING_RIGHTS:
    "Congratulations on obtaining the following rights",
  WE_WILL_EMAIL_YOU_AN_ORDER_CONFIRMATION_WITH_DETAILS_INFO:
    "We'll email you an order confirmation with details info.",
  IF_YOU_HAVE_QUESTIONS_ABOUT_YOUR_ORDER_YOU_CAN_EMAIL_US_AT:
    "If you have questions about your order, you can email us at",
  VIEW_NOW: "View Now",
  BILL: "Bill",
  MY_BANK: "My Bank",
  RESUME_PLUS: "Resume Plus",
  CANCEL_PLUS: "Cancel Plus",
  ARE_YOU_SURE_YOU_WANT_TO_CANCEL_PLUS_YOU_WILL_LOSE:
    "Are you sure you want to cancel Plus, you will lose the plus benefits",
  SUMBIT: "Submit",
  CONFIRM_RESUME: "Confirm Resume",
  ARE_YOU_SURE_YOU_WANT_TO_RESUME_THE_PLUS_IT_WILL_START_BILLING_AGAIN_ON:
    "Are you sure you want to resume the Plus? It will start billing again on",
  CONFIRM: "Confirm",
  NOTICE: "Notice",
  PLEASE_INSTALL_A_SOFTWARE_TOKEN_INSTALLER_FROM_YOUR_APPLICATION_LIBRARY:
    "Please install a software token installer from your application library, such as Google Authenticator, and then scan this QR code, more information can be found in the user manual",
  CAN_NOT_SCAN_THE_QR_CODE: "Can't scan the QR code?",
  TO_ADD_AN_ENTRY_MANUALLY:
    "To add an entry manually, please provide the following information in the mobile application ",
  SECRET_KEY: "Secret Key",
  AUTH_METHOD: "Auth Method",
  TIME_BASED: "Time-based",
  REGISTER_WITH_THE_DUAL_AUTHENTICATION_APPLICATION:
    "Register with the Dual Authentication application",
  PIN_CODE: "Pin code",
  PROFILE_PHOTO: "Profile Photo",
  LOGOUT: "Log Out",
  USER_NAME: "User Name",
  PASSWORD_MUST_BE_8_16_CHARACTERS_AND_CONTAIN_BOTH_NUMBERS_AND_LETTERS_SPECIAL_CHARACTERS:
    "Password must be 8-16 characters and contain both numbers and letters/special characters",
  SAVE: "Save",
  ARE_YOU_SURE_YOU_WANT_TO_LOG_OUT: "Are you sure you want to log out",
  MY_RENDER: "My Render",
  MORE: "More",
  ORIGINAL: "Original",
  SUCCESSFULLY_DELETED: "Successfully deleted",
  REMOVE_CARD: "Remove Card",
  ADD_CARD: "Add Card",
  ARE_YOU_SURE_REMOVE_CARD: "Are you sure Remove card",
  PAYMENT: "Payment",
  PENDING: "Pending",
  PAYMENT_FAILED: "Payment Failed",
  WELCOME_TO_LOG_IN_TO_YOUR_AI_ACCOUNT: "Welcome to log in to your AI account",
  DUAL_AUTHENTICATION_2FA: "Dual Authentication (2FA)",
  A_MESSAGE_CONTAINING_A_VERIFICATION_CODE_HAS_BEEN_SENT_TO_YOUR_GOOGLE_AUTHENTICATOR:
    "A message containing a verification code has been sent to your Google Authenticator.Please enter the 6-digit two-factor security code to continue",
  BY_PROVIDING_YOUR_CARD_DETAILS:
    "By providing your card details, you allow KainoAI to charge your card for future payments in accordance with their terms",
  SECURELY_SAVE_MY_INFORMATION_FOR_1_CLICK_CHECKOUT:
    "Securely save my information for 1-click checkout",
  ENTER_YOUR_PHONE_NUMBER_TO_CREATE_A_LINK_ACCOUNT:
    "Enter your phone number to create a Link account and pay faster on KainoAI and everywhere Link is accepted.",
  ENTER_ADDRESS_MANUALLY: "Enter address manually",
  I_AM_PURCHASING_AS_A_BUSINESS: "I'm purchasing as a business",
  YOU_WILL_BE_CHARGED_THE_AMOUNT_LISTED_ABOVE_EVERY_MONTH_UNTIL_YOU_CANCEL:
    "You'll be charged the amount listed above every month until you cancel.We may chage our prices as described in our Terms of Use. You can cancel any time. By subscribing, you agree to KainoAI's Terms of Use and Privacy Policy",
  REGISTER: "Register",
  TERM_OF_USE_PRIVACY_POLICY: "Term of use. Privacy policy",
  SOURCE_IMAGE: "Source Image",
  GENERATED_IMAGE: "Generated Image",
  CREATION_TIME: "Creation Time",
  RESEND_INSTRUCTIONS: "Resend Instructions",
  PLAN: "Plan",
  ADD_A_CREDIT_DEBIT_CARD: "Add a Credit / Debit Card",
  RESET: "Reset",
  YOU_HAVE: "Enjoy ",
  FREE_TEST_OPPORTUNITIES: " Complimentary Credits Daily",
  RESOLUTION_IS_REQUIRED: "Resolution is required",
  ACCOUNT: "Account",
  EMAIL_LENGTH_CANNOT_EXCEED_50_CHARACTERS:
    "Email length cannot exceed 50 characters!",
  NUMBER_OF_RENDERING_TIMES_AVAILABLE: "Number of rendering times available",
  TIME_DUE: "Time due",
  RENDERING_FAILED_FREE_TIMES_HAVE_BEEN_EXHAUSTED:
    "Rendering failed, free trial times have been used up, please contact the administrator.",
  MAKE_THE_DESIGN_EASIER: "Make The Design Easier",
  UPLOAD_SPACE_PHOTO_CONFIGURE_RENDERING_PARAMETERS:
    "Upload Space Photo, Configure Rendering Parameters",
  GET_YOUR_DESIGN: "Get Your Design",
  FREE_TESTER: "Free Tester",
  REGISTER_ACCOUNT: "Register Account",
  CLAIM_100_PROFESSIONAL_RENDERING_CREDITS_FOR_FREE:
    "Claim 100 Professional Rendering Credits for Free!",
  DO_NOT_SELECT: "Don't select",
  VIRTUAL_STAGING: "Virtual Staging",
  INTERIOR_DESIGN: "Interior Design",
  FREESTYLE: "Freestyle",
  LIVING_ROOM: "living room",
  BEDROOM: "bedroom",
  BATH_ROOM: "bath room",
  ATTIC: "attic",
  KITCHEN: "kitchen",
  DINING_ROOM: "dining room",
  STUDY_ROOM: "study room",
  HOME_OFFICE: "home office",
  GAMING_ROOM: "gaming room",
  HOUSE_EXTERIOR: "house exterior",
  OUTDOOR_POOL_AREA: "outdoor pool area",
  OUTDOOR_PATIO: "outdoor patio",
  OUTDOOR_GARDEN: "outdoor garden",
  MEETING_ROOM: "meeting room",
  WORKSHOP: "workshop",
  FITNESS_GYM: "fitness gym",
  COFFEE_SHOP: "coffee shop",
  CLOTHING_STORE: "clothing store",
  WALK_IN_CLOSET: "walk in closet",
  TOILET: "toilet",
  RESTAURANT: "restaurant",
  OFFICE: "office",
  COWORKING_SPACE: "coworking space",
  HOTEL_LOBBY: "hotel lobby",
  HOTEL_ROOM: "hotel room",
  HOTEL_BATHROOM: "hotel bathroom",
  EXHIBITION_SPACE: "exhibition space",
  ONSEN: "onsen",
  MUDROOM: "mudroom",
  DROP_ZONE: "drop zone",
  EASTER: "easter",
  MODERN: "modern",
  MINIMALIST: "minimalist",
  CONTEMPORARY: "contemporary",
  SCANDINAVIAN: "scandinavian",
  INTERIOR_AI: "interior ai",
  ZEN: "zen",
  MIDCENTURY_MODERN: "midcentury modern",
  TROPICAL: "tropical",
  BIOPHILIC: "biophilic",
  INDUSTRIAL: "industrial",
  ART_DECO: "art deco",
  FARMHOUSE: "farmhouse",
  JAPANESE_DESIGN: "japanese design",
  RUSTIC: "rustic",
  BOHEMIAN: "bohemian",
  COASTAL: "coastal",
  VINTAGE: "vintage",
  COTTAGECORE: "cottagecore",
  FRENCH_COUNTRY: "french country",
  ART_NOUVEAU: "art nouveau",
  CYBERPUNK: "cyberpunk",
  MAXIMALIST: "maximalist",
  BAROQUE: "baroque",
  VAPORWAVE: "vaporwave",
  SKI_CHALET: "ski chalet",
  CHRISTMAS: "christmas",
  SKETCH: "sketch",
  TRIBAL: "tribal",
  MEDIEVAL: "medieval",
  CHINESE_NEW_YEAR: "chinese new year",
  HALLOWEEN: "halloween",
  NEOCLASSIC_PRO: "neoclassic (pro)",
  AVAILABLE_TOKEN: "Available Token",
  PREVIEW: "Preview",
  STYLE_IS_REQUIRED: "Style is required",
  ROOM_IS_REQUIRED: "Room is required",
  MODE_IS_REQUIRED: "Mode is required",
  SPARE: "Spare",
  FREQUENCY: "Frequency",
  RE_RENDER: "Re render",
  DOWNLOAD_RENDERING: "Download rendering",
  VERSIONS: "Versions",
  CANCEL_RENDERINH: "Cancel Rendering",
  SELECT: "Select",
  TIP: "Tip",
  CANCEL_RENDERING_TIP_TEXT:
    " Rendering in progress. If you choose to cancel, the current rendering count will not be refunded and will still be deducted. Do you still want to cancel?",
  LOGIN_FAILED_ERROR_MESSAGE: "Login Failed error message:",
  GENERATE: "New",
  RENDER_FAILURE_TIP: "Render Failure Tip:",
  INSUFFICIENT_NUMBER_OF_RENDERINGS: "Insufficient number of renderings",
  COPY_IMAGE_LINK: "Copy image link",
  START: "Start",
  SHARED_LINK: "Shared Link",
  RESIDENTIAL: "Residential",
  COMMERCIAL: "Commercial",
  DESGIN: "Design",
  TODAY: "Today",
  YESTERDAY: "Yesterday",
  RECENT_7DAYS: "Previous 7Days",
  RECENT_30DAYS: "Previous 30Days",
  EARLIER: "Earlier",
  WE_ARE_COMMITTED_TO_YOUR_PRIVACY:
    "We're committed to your privacy. KainoAI uses the information you provide to us to contact you about our relevant content, products, and services. You may unsubscribe from these communications at any time. For more information, check out our",

  PRIVACY_POLICY: "Privacy Policy",
  UPLOAD_PICTURE: "Upload Picture",
  CHOOSE_THE_STYLE: "Choose The Style",
  CHOOSE_THE_ROOM: "Choose The Room",
  CHOOSE_THE_MODE: "Choose The Mode",
  CHOOSE_THE_RESOLUTION: "Choose The Resolution",
  PLEASE_WAIT_GENERATING_THE_DESIGN: "Please wait, generating the design...",
  DETAILS: "Details",
  DOWNLOAD: "Download",
  RESTART: "Rerender",
  SHARE: "Share",
  FULL: "Full view",
  AVAILABLE_CREDIT: "Available Credit",
  ARE_YOU_SURE_YOU_WANT_TO_RENDER_IT_AGAIN:
    "Are you sure you want to render it again?",
  PROFILE: "Profile",
  RENDER_HISTORY: "Render History",
  GENERATES: "Generate",
  WAIVE: "Waive",
  RENDER_AGAIN: "Render Again",
  MEMBERS: "Members",
  ACTIVITIES: "Activities",
  INVITE_YOUR_FRIENDS: "Invite Your Friends",
  CREDIT: "Credits",
  DATA_PRIVACY_MARKETING_POLICYKAINOAI:
    "Data Privacy & Marketing Policy – KainoAI",
  WE_ARE_COMMITTED_TO_ENSURING_THE_PRIVACY_AND_SECURITY_OF_YOUR_PERSONAL_INFORMATION:
    "We are committed to ensuring the privacy and security of your personal information. This Privacy Policy outlines how we collect, use, and protect your data. By using our services, you agree to the terms and practices described herein.",
  INFORMATION_WE_COLLECT: "1. Information We Collect",
  WE_MAY_COLLECT_THE_FOLLOWING_TYPES_OF_INFORMATION:
    "We may collect the following types of information:",
  PERSONAL_INFORMATION_NAME_EMAIL_ADDRESS_CONTACT_DETAILS:
    "Personal Information: Name, email address, contact details.",
  DESIGN_PREFERENCES_INFORMATION_YOU_PROVIDE_REGARDING_YOUR_DESIGN_NEEDS_AND_PREFERENCES:
    "Design Preferences: Information you provide regarding your design needs and preferences.",
  USAGE_DATA_INTERACTION_DATA_SUCH_AS_YOUR_INTERACTIONS_WITH_OUR_WEBSITE_AND_DESIGN_TOOLS:
    "Usage Data: Interaction data, such as your interactions with our website and design tools.",
  WE_COLLECT_THIS_INFORMATION_TO_DELIVER_PERSONALIZED_AND_EFFICIENT_GRAPHIC_DESIGN_SOLUTIONS_TO_YOU:
    "We collect this information to deliver personalized and efficient graphic design solutions to you.",
  HOW_WE_USE_YOUR_INFORMATION: "2. How We Use Your Information",
  WE_USE_YOUR_INFORMATION_TO: "We use your information to:",
  CREATE_AND_DELIVER_GRAPHIC_DESIGNS_BASED_ON_YOUR_PREFERENCES:
    "Create and deliver graphic designs based on your preferences.",
  COMMUNICATE_WITH_YOU_ABOUT_YOUR_DESIGN_PROJECTS_AND_UPDATES:
    "Communicate with you about your design projects and updates.",
  IMPROVE_OUR_SERVICES_AND_OFFERINGS_BASED_ON_USER_INTERACTIONS:
    "Improve our services and offerings based on user interactions",
  DATA_SECURITY: "3. Data Security",
  WE_TAKE_THE_SECURITY_OF_YOUR_DATA_SERIOUSLY:
    "We take the security of your data seriously. We implement technical and organizational measures to protect your information from unauthorized access, loss, misuse, or alteration. However, please be aware that no method of data transmission over the internet is completely secure.",
  SHARING_OF_INFORMATION: "4. Sharing of Information",
  WE_DO_NOT_SHARE_YOUR_PERSONAL_INFORMATION_WITH_THIRD_PARTIES_FOR_MARKETING_PURPOSES:
    "We do not share your personal information with third parties for marketing purposes. However, we may share your information with:",
  OUR_SERVICE_PROVIDERS_WHO_ASSIST_US_IN_DELIVERING_OUR_DESIGN_SERVICES:
    "Our service providers who assist us in delivering our design services.",
  LEGAL_AND_REGULATORY_AUTHORITIES:
    "Legal and regulatory authorities, if required by law.",
  COOKIES_AND_TRACKING_TECHNOLOGIES: "5.Cookies and Tracking Technologies",
  OUR_WEBSITE_AND_SERVICES_MAY_USE_COOKIES:
    "Our website and services may use cookies and similar tracking technologies to enhance your browsing experience and collect usage information. You can adjust your browser settings to manage your cookie preferences.",
  YOUR_CHOICES: "6. Your Choices",
  YOU_HAVE_THE_RIGHT_TO: "You have the right to:",
  ACCESS_CORRECT_OR_DELETE_YOUR_PERSONAL_INFORMATION:
    "Access,correct, or delete your personal information.",
  OPT_OUT_OF_RECEIVING_MARKETING_COMMUNICATIONS:
    "Opt-out of receiving marketing communications.",
  RAISE_OBJECTIONS_OR_RESTRICT_THE_PROCESSING_OF_YOUR_DATA:
    "Raise objections or restrict the processing of your data.",
  TO_EXERCISE_THESE_RIGHTS_PLEASE_CONTACT_US_AT:
    "To exercise these rights, please contact us at enquiry@kainoai.com.",
  CHILDREN_PRIVACY: "7. Children's Privacy",
  OUR_SERVICES_ARE_NOT_INTENDED_FOR_INDIVIDUALS_UNDER_THE_AGE_OF:
    "Our services are not intended for individuals under the age of [12]. We do not knowingly collect personal information from children.",
  CHANGES_TO_PRIVACY_POLICY: "8. Changes to Privacy Policy",
  WE_MAY_UPDATE_THIS_PRIVACY_POLICY_PERIODICALLY_TO_REFLECT_CHANGES_IN_OUR_PRACTICES_AND_SERVICES:
    "We may update this Privacy Policy periodically to reflect changes in our practices and services. The revised version will be posted on our website along with the effective date.",
  FOR_ANY_INQUIRIES_ABOUT_OUR_PRIVACY_POLICY_OR_DATA_PRACTICES: `For any inquiries about our Privacy Policy or data practices, please contact us at ${CONTACT_EMAIL}. Your privacy is important to us, and we are committed to addressing any concerns you may have.`,
  KAINOAI_OPERATION_TEAM: "KainoAI Operation Team",
  CONTACT_EMAIL: "Contact Email: ",
  ISSUE: "Issue",
  CREDITS: "Association",
  CHOOSE_CREDITS: "Choose Credits",
  INVITE_MEMBER: "Invite Member",
  TOP_UP: "Top Up",
  YOUR_AVAILABLE_CREDITS: "Your Available Credits",
  ASSOCIATION: "Association",
  DISASSOCIATE: "Disassociate",
  MULTIPLES_OF_100_CAN_BE_ALLOCATED_EACH_TIME:
    "Multiples of 100 can be allocated each time",
  INVITATIONS: "Invitations",
  PLEASE_ENTER_INVITE_EMAIL: "Please Enter invite Email",
  THE_INPUT_IS_NOT_VALID_EMAIL: "The input is not valid E-mail!",
  PLEASE_INPUT_YOUR_EMAIL: "Please input your E-mail!",
  COPY_LINK: "Copy Link",
  YOUR_REFERRAL_LINK: "Your Referral Link",
  DONE: "Done",
  AVAILABLE_CREDITS: "Available Credits",
  SPENT: "Spent",
  ALL: "All",
  SUCCESSFUL_DISTRIBUTION: "Successful distribution",
  ASSOCIATED_MEMBER: "Associate members",
  RECEVED_CREDITS: "Received credits",
  INVITATION_TO_JOIN: "Association",
  CONSENT_CONFIRMED: "Consent confirmed",
  OF_ESTABLISHING_AN_ASSOCIATED_APPLICATION:
    "of establishing an associated application?",
  WHO_CAN_ASSIGN_CREDITS_TO_YOU:
    "By agreeing to this, you will establish a relationship with the other organisation and they can assign Credits to you.",
  CONFIRM_DELETING_THIS_RENDERING_RECORD:
    "Confirm deleting this rendering record?",
  INITIALISATION_GIFT: "Initialisation gift",
  THE_NUMBER_OF_TIMES_THAT_CAN_BE_ALLOCATED_CANNOT_EXCEED:
    "The number of times that can be allocated cannot exceed",
  BINDING_FAILED: "Binding Failed",
  DOES_IT_JUMP_TO_THE_ANDROID_BUNDLE: "Whether to jump to Android binding?",
  WEB_BINDING: "Web Binding",
  ANDROID_BINDING: "Android Binding",
  TRIGGERED_TIME: "Trigger Time",
  SEARCH: "Search",
  NAME: "Name",
  SURPLUS_CREDIT: "Surplus Credit",
  NUMBER_OF_NEW_ACCOUNTS: "Automatic allocation for new account",
  SENDING:"Sending",
  SYSTEM_MAINTENANCE_NOTICE:"System Maintenance Notice",
  INDIVIDUAL_CENTRE:"Individual Centre",
  RESEND_VERIFICATION_EMAIL:"Resend Email",
  CREATED_SUCCESSFULLY:"Created Successfully",
  EDIT:"Edit",
  STATUS:"Status",
  LAST_USED:"Last used",

  PLEASE_RATE_YOUR_SATISFACTION_WITH_THE_RENDERING_BY_KAINOAI:"Please rate your satisfaction with the rendering by KainoAI.",
  VERY_UNSATISFIED:"Very unsatisfied",
  UNSATISFIED:"Unsatisfied",
  NEUTRAL:"Neutral",
  SATISFIED:"Satisfied",
  VERY_SATISFIED:"Very satisfied"
};
